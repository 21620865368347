import moment from "moment";
const utils = {
  year() {
    return moment(new Date()).format("YYYY");
  },
  toRupiah(nominal) {
    if (nominal > 0) {
      return nominal.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
    }
    return "0";
    // return nominal ? nominal.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') : 0
  },
  toDate(tanggal) {
    return tanggal ? moment(String(tanggal)).format("DD-MM-YYYY") : "";
  },
  toDateStringMount(tanggal) {
    return moment(String(tanggal)).format("DD MMMM YYYY");
  },
  toDateTime(tanggal) {
    return moment(String(tanggal)).format("DD-MM-YYYY hh:mm");
  },
};
export default utils;
