import LoanService from "@/services/loan.service.js";
import store from "@/store";
import alerts from "../alerts/alert";
const loan = {
  namespaced: true,
  state: {
    openstatus: false,
    steppers: [],
    inquiries: [],
    histories: [],
    amortisasies: [],
    status: [],
    myloan: [],
    historyPelunasan: [],
    listAmortisasi: {
      list: [],
      amortisasi: null,
    },
    loading: true,
    historyApproval: [],
    statusPeminjaman: null,
    jenisPengajuan: null,
    modalFormPelunasan: false,
    dataPelunasan: {
      sisabunga: 0,
      sisapinjaman: 0,
      total: 0,
      loan: {
        pelunasan: {
          SisaOS: "0",
        },
      },
    },
    statusPelunasan: null,
    statusPretermination: {},
    loadingPretermination: false,
    historyAll: [],
  },
  actions: {
    ListAmortisasi({ commit }, AgreementNo) {
      LoanService.getListAmortisasi(AgreementNo).then(({ data }) => {
        const newData = [];
        data.list.forEach((dt, index) => {
          if (index === 0) {
            newData.push({
              AgreementNo: null,
              InstallmentAmount: null,
              InstallmentDueDate: null,
              InterestAmount: null,
              OSPrincipalAmount: data.TotalPokok,
              PaidDate: null,
              PrincipalAmount: null,
            });
            newData.push(dt);
          } else {
            newData.push(dt);
          }
        });
        const newDatas = data;
        newDatas.list = newData;
        commit("SET_LOADING", false);
        commit("SET_LIST_AMORTISASI", newDatas);
      });
    },
    formAjukan({ commit, state }, AgreementNo) {
      commit("SET_LOADING", true);
      LoanService.getDetailPelunasan(AgreementNo).then((response) => {
        state.dataPelunasan = response.data;
        commit("SET_LOADING", false);
        commit("OPEN_FORM_PELUNASAN", true);
      });
    },
    postPelunasan({ commit, state }) {
      store.dispatch("loading/setLoading", true);
      const params = {
        id: state.dataPelunasan.loan.id,
        NIK: state.dataPelunasan.loan.EmployeeID,
        TglPengajuan: new Date().toISOString().slice(0, 10),
        PaidAmount: state.dataPelunasan.total,
        AgreementNo: state.dataPelunasan.loan.AgreementNo,
      };
      LoanService.postPelunasan(params).then((response) => {
        commit("OPEN_FORM_PELUNASAN", false);
        store.dispatch("loading/setLoading", false);
        store.dispatch(
          "loan/getStatusPetermination",
          state.dataPelunasan.loan.AgreementNo
        );
        alerts.sweetalertToast(response.icon, response.status);
      });
    },
    kirimPelunasan({ state, commit }, payload) {
      store.dispatch("loading/setLoading", true);
      LoanService.UploadBuktiPelunasan(
        state.dataPelunasan.loan.pelunasan.id,
        payload
      ).then((response) => {
        commit("OPEN_FORM_PELUNASAN", false);
        store.dispatch(
          "loan/getStatusPetermination",
          state.dataPelunasan.loan.AgreementNo
        );
        store.dispatch("loading/setLoading", false);
        alerts.sweetalertToast(response.icon, response.status);
      });
    },
    getInquiries({ commit }, nik) {
      LoanService.getInquiryStatus(nik).then((response) => {
        commit("SET_INQUIRY_RECORDS", response.data);
      });
    },
    getAmortisasi({ commit }, nik) {
      LoanService.getHistoryAmortisasi(nik).then((response) => {
        commit("SET_AMORTISASI", response.data);
      });
    },
    getHistories({ commit }, nik) {
      LoanService.getHistoryStatus(nik).then((response) => {
        commit("SET_HISTORY_RECORDS", response.data);
      });
    },
    async getHistoryAll({ commit }, { nik }) {
      try {
        // Panggil API untuk mendapatkan riwayat pinjaman berdasarkan NIK
        const response = await LoanService.getHistoryAll(nik); // Panggil API
        commit("SET_LOADING", false);
        commit("SET_HISTORY_ALL", response.data); // Simpan data riwayat pinjaman
      } catch (error) {
        commit("SET_LOADING", false);
        console.error("Error fetching loan history:", error);
      }
    },
    getStatusPelunasan({ state, commit }, nik) {
      if (state.inquiries === null) {
        store.dispatch("loading/setLoading", true);
      }
      LoanService.getStatusPelunasan(nik).then((response) => {
        store.dispatch("loading/setLoading", false);
        commit("SET_STATUS_RECORDS", response.data);
      });
    },

    getStatusPetermination({ state, commit }, AgreementNo) {
      state.loadingPretermination = true;
      if (state.inquiries === null) {
        store.dispatch("loading/setLoading", true);
      }
      LoanService.getStatusPetermination(AgreementNo).then((response) => {
        console.log(JSON.stringify(response.data));

        state.loadingPretermination = false;
        store.dispatch("loading/setLoading", false);
        commit("SET_STATUS_PRETERMINATION", response.data);
      });
    },

    setMyLoan({ commit }, data) {
      commit("SET_MY_LOAN", data);
    },
    getHistoryPelunasan({ commit }, nik) {
      LoanService.getHistoryPelunasan(nik).then((response) => {
        commit("SET_HISTORY_PELUNASAN", response.data);
      });
    },
    modalStatusPengjuan({ commit, state }, payload) {
      LoanService.getHistoryApproval(payload).then((response) => {
        commit("SET_HISTORY_SPPROVAL", response);
        commit("SET_OPEN_STATUS", payload.Open);
        commit("SET_STATUS_PEMINJAMAN", payload.Status);
        state.jenisPengajuan = payload.JenisPengajuan;
      });
    },
    getStepper({ commit }, companyID) {
      LoanService.getStepper(companyID).then((response) => {
        commit("SET_STEPPERS", response);
      });
    },
    async cancelPengajuan({ commit }, params) {
      commit("SET_OPEN_STATUS", false);
      await alerts.questionConfirmation(
        params,
        "loan/lanjukanCancel",
        "Yakin dicancel ?",
        "",
        "Cancel"
      );
    },
    async lanjukanCancel({ commit }, params) {
      commit("SET_OPEN_STATUS", false);
      store.dispatch("loading/setLoading", true);
      await LoanService.cancelPengajuan(params).then((response) => {
        // console.log(JSON.stringify(response));
        store.dispatch("loading/setLoading", false);
        store.dispatch("loan/getHistories", response.NIK);
        alerts.sweetalertToast(response.icon, response.message);
        setTimeout(() => {
          window.location = "/home";
        }, 1500);
      });
    },
  },
  mutations: {
    SET_LIST_AMORTISASI(state, data) {
      state.listAmortisasi = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_HISTORY_RECORDS(state, data) {
      state.histories = data;
    },
    SET_INQUIRY_RECORDS(state, data) {
      state.inquiries = data;
    },
    SET_STATUS_RECORDS(state, data) {
      state.status = data;
    },
    SET_STATUS_PRETERMINATION(state, data) {
      state.statusPretermination = data;
    },
    SET_MY_LOAN(state, data) {
      state.myloan = data;
    },
    SET_HISTORY_PELUNASAN(state, data) {
      state.historyPelunasan = data;
    },
    SET_AMORTISASI(state, data) {
      state.amortisasies = data;
    },
    SET_STEPPERS(state, data) {
      state.steppers = data;
    },
    SET_OPEN_STATUS(state, data) {
      state.openstatus = data;
    },
    SET_HISTORY_SPPROVAL(state, data) {
      state.historyApproval = data;
    },
    SET_STATUS_PEMINJAMAN(state, data) {
      state.statusPeminjaman = data;
    },
    OPEN_FORM_PELUNASAN(state, data) {
      state.modalFormPelunasan = data;
    },
    SET_STATUS_PELUNASAN(state, data) {
      state.statusPelunasan = data;
    },
    SET_HISTORY_ALL(state, data) {
      state.historyAll = data;
    },
  },
};

export default loan;
