<template>
  <v-dialog
    v-model="state.openstatus"
    persistent
    width="100%"
    max-width="60vmax"
  >
    <v-card class="text-center">
      <div class="text-right">
        <v-btn
          x-small
          class="ma-2 error mx-2"
          fab
          @click="state.openstatus = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <h2 class="mb-4">Status Pengajuan {{ state.jenisPengajuan }}</h2>
      <v-divider></v-divider>
      <template>
        <div v-if="state.jenisPengajuan === 'NST'">
          <v-stepper v-model="e6" vertical class="hidden-lg-and-up">
            <v-stepper-step
              :rules="[
                () =>
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? false
                    : true,
              ]"
              :complete="
                (state.statusPeminjaman === 'RJT' ||
                  state.statusPeminjaman === 'CNCL') &&
                state.historyApproval.length === 0
                  ? false
                  : true
              "
              step="1"
            >
              Pemohon
            </v-stepper-step>
            <v-stepper-step
              v-if="state.steppers.length > 2"
              step="2"
              :rules="[() => checkStep(state.steppers[2].jobtitle)]"
              :complete="checkComplete(state.steppers[2].jobtitle)"
            >
              {{ state.steppers[2].jobtitle }}
            </v-stepper-step>
            <v-stepper-content
              v-if="state.steppers.length > 2"
              :class="
                checkComplete(state.steppers[2].jobtitle)
                  ? 'brd-green'
                  : 'brd-grey'
              "
              :step="checkNote(state.steppers[2].jobtitle)"
            >
              <v-card
                color="#dddddd6e"
                class="mb-12 text-left pa-2"
                height="200px"
              >
                {{
                  state.historyApproval[2] ? state.historyApproval[2].Notes : ""
                }}</v-card
              >
            </v-stepper-content>
          </v-stepper>

          <v-stepper class="hidden-sm-and-down" vertical alt-labels>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :rules="[
                  () =>
                    (state.statusPeminjaman === 'RJT' ||
                      state.statusPeminjaman === 'CNCL') &&
                    state.historyApproval.length === 0
                      ? false
                      : true,
                ]"
                :complete="
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? false
                    : true
                "
              >
                Pemohon
              </v-stepper-step>

              <v-divider
                :color="
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? ''
                    : 'primary'
                "
              ></v-divider>

              <v-stepper-step
                v-if="state.steppers.length > 2"
                step="2"
                :rules="[() => checkStep(state.steppers[2].jobtitle)]"
                :complete="checkComplete(state.steppers[2].jobtitle)"
              >
                {{ state.steppers[2].jobtitle }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <div v-else>
          <v-stepper v-model="e6" vertical class="hidden-lg-and-up">
            <v-stepper-step
              :rules="[
                () =>
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? false
                    : true,
              ]"
              :complete="
                (state.statusPeminjaman === 'RJT' ||
                  state.statusPeminjaman === 'CNCL') &&
                state.historyApproval.length === 0
                  ? false
                  : true
              "
              step="1"
            >
              Pemohon
            </v-stepper-step>

            <v-stepper-content class="brd-green" step="2">
              <v-card color="#dddddd6e" class="mb-12" height="200px"></v-card>
            </v-stepper-content>

            <v-stepper-step
              v-if="state.steppers.length > 0"
              step="2"
              :rules="[() => checkStep(state.steppers[0].jobtitle)]"
              :complete="checkComplete(state.steppers[0].jobtitle)"
            >
              {{ state.steppers[0].jobtitle }}
            </v-stepper-step>

            <v-stepper-content
              v-if="state.steppers.length > 0"
              :class="
                checkComplete(state.steppers[0].jobtitle)
                  ? 'brd-green'
                  : 'brd-grey'
              "
              :step="checkNote(state.steppers[0].jobtitle)"
            >
              <v-card
                color="#dddddd6e"
                class="mb-12 text-left pa-2"
                height="200px"
                >{{
                  state.historyApproval[0] ? state.historyApproval[0].Notes : ""
                }}</v-card
              >
            </v-stepper-content>

            <v-stepper-step
              v-if="state.steppers.length > 1"
              step="3"
              :rules="[() => checkStep(state.steppers[1].jobtitle)]"
              :complete="checkComplete(state.steppers[1].jobtitle)"
            >
              {{ state.steppers[1].jobtitle }}
            </v-stepper-step>

            <v-stepper-content
              v-if="state.steppers.length > 1"
              :class="
                checkComplete(state.steppers[1].jobtitle)
                  ? 'brd-green'
                  : 'brd-grey'
              "
              :step="checkNote(state.steppers[1].jobtitle)"
            >
              <v-card
                color="#dddddd6e"
                class="mb-12 text-left pa-2"
                height="200px"
                >{{
                  state.historyApproval[1] ? state.historyApproval[1].Notes : ""
                }}</v-card
              >
            </v-stepper-content>

            <v-stepper-step
              v-if="state.steppers.length > 2"
              step="4"
              :rules="[() => checkStep(state.steppers[2].jobtitle)]"
              :complete="checkComplete(state.steppers[2].jobtitle)"
            >
              {{ state.steppers[2].jobtitle }}
            </v-stepper-step>
            <v-stepper-content
              v-if="state.steppers.length > 2"
              :class="
                checkComplete(state.steppers[2].jobtitle)
                  ? 'brd-green'
                  : 'brd-grey'
              "
              :step="checkNote(state.steppers[2].jobtitle)"
            >
              <v-card
                color="#dddddd6e"
                class="mb-12 text-left pa-2"
                height="200px"
              >
                {{
                  state.historyApproval[2] ? state.historyApproval[2].Notes : ""
                }}</v-card
              >
            </v-stepper-content>
          </v-stepper>

          <v-stepper class="hidden-sm-and-down" vertical alt-labels>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :rules="[
                  () =>
                    (state.statusPeminjaman === 'RJT' ||
                      state.statusPeminjaman === 'CNCL') &&
                    state.historyApproval.length === 0
                      ? false
                      : true,
                ]"
                :complete="
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? false
                    : true
                "
              >
                Pemohon
              </v-stepper-step>

              <v-divider
                :color="
                  (state.statusPeminjaman === 'RJT' ||
                    state.statusPeminjaman === 'CNCL') &&
                  state.historyApproval.length === 0
                    ? ''
                    : 'primary'
                "
              ></v-divider>

              <v-stepper-step
                v-if="state.steppers.length > 0"
                step="2"
                :rules="[() => checkStep(state.steppers[0].jobtitle)]"
                :complete="checkComplete(state.steppers[0].jobtitle)"
              >
                {{ state.steppers[0].jobtitle }}
              </v-stepper-step>

              <v-divider
                v-if="state.steppers.length > 0"
                :color="
                  checkComplete(state.steppers[0].jobtitle) ? 'primary' : ''
                "
              ></v-divider>

              <v-stepper-step
                v-if="state.steppers.length > 1"
                step="3"
                :rules="[() => checkStep(state.steppers[1].jobtitle)]"
                :complete="checkComplete(state.steppers[1].jobtitle)"
              >
                {{ state.steppers[1].jobtitle }}
              </v-stepper-step>
              <v-divider
                v-if="state.steppers.length > 1"
                :color="
                  checkComplete(state.steppers[1].jobtitle) ? 'primary' : ''
                "
              ></v-divider>

              <v-stepper-step
                v-if="state.steppers.length > 2"
                step="4"
                :rules="[() => checkStep(state.steppers[2].jobtitle)]"
                :complete="checkComplete(state.steppers[2].jobtitle)"
              >
                {{ state.steppers[2].jobtitle }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
      </template>
      <h2
        class="hidden-sm-and-down mb-4 mt-10"
        v-if="
          state.statusPeminjaman === 'RJT' ||
          (state.statusPeminjaman === 'CNCL' &&
            state.historyApproval.length > 0)
        "
      >
        Alasan Reject/Cancel
      </h2>
      <p
        class="hidden-sm-and-down text-center pa-4 Alasan"
        v-if="NoteReject !== '0' || NoteReject !== 0"
      >
        {{ NoteReject }}
      </p>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "StatusPengajuan",
  data() {
    return {
      e6: 1,
      currentStep: 0,
      step2: false,
    };
  },
  computed: {
    ...mapState("loan", {
      state: (state) => state,
    }),
    NoteReject() {
      const index = this.state.historyApproval.findIndex(
        (item) => item.ApprovalStatus === "RJT" && item.Notes !== null
      );
      if (index >= 0) {
        return this.state.historyApproval[index].Notes;
      }

      const index2 = this.state.historyApproval.findIndex(
        (item) => item.ApprovalStatus === "CNCL" && item.Notes !== null
      );
      if (index2 >= 0) {
        return this.state.historyApproval[index].Notes;
      }

      return "";
    },
  },
  methods: {
    ...mapActions("loan", ["modalStatusPengjuan"]),
    checkStep(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "RJT") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    checkNote(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "RJT") {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    checkComplete(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "APV") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.v-stepper__content.brd-green {
  color: aqua !important;
  border-color: aqua !important;
}

.Alasan {
  font-size: 24px !important;
  padding: 30px !important;
}
</style>
