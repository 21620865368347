<template>
  <div>
    <v-dialog v-model="state.modalFormPelunasan" persistent max-width="60vmax">
      <v-card class="text-center">
        <div class="text-right">
          <v-btn
            x-small
            class="ma-2 error mx-2"
            fab
            @click="state.modalFormPelunasan = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <h2 class="mb-4">PELUNASAN</h2>
        <!-- <v-divider></v-divider> -->
        <v-card class="text-center">
          <v-card-text
            v-if="
              state.dataPelunasan.loan.pelunasan !== null &&
              state.dataPelunasan.loan.pelunasan.Status === 'PT2'
            "
          >
            <h4>Jumlah yang harus dibayar</h4>
            <h3 class="text-warning">
              Rp
              {{
                toRupiah(
                  state.dataPelunasan
                    ? state.dataPelunasan.loan.pelunasan.SisaOS
                    : "0"
                )
              }}
            </h3>
            <br />
            <v-divider></v-divider>
            <br />
            <h4>Upload Bukti Transfer</h4>
            <v-file-input
              outlined
              dense
              v-model="bukti"
              @change="getBase64()"
              label="Bukti Transfer"
            ></v-file-input>
            <v-btn
              @click="handleKirimPelunasan({ BuktiPelunasan: BuktiPelunasan })"
              class="primary"
              >K I R I M</v-btn
            >
          </v-card-text>
          <v-card-text v-else>
            <h4>Status Pengajuan Pelunasan</h4>
            <h3 class="text-warning">{{ state.status.Status }}</h3>
            <v-icon x-large>mdi-clock-time-three-outline</v-icon>
            <v-divider></v-divider>
            <br />
            <h4>Jumlah yang harus dibayar</h4>
            <h3 class="text-warning">
              Rp {{ toRupiah(state.dataPelunasan.total) }}
            </h3>
            <h4>
              (OSP Rp{{ toRupiah(state.dataPelunasan.sisapinjaman) }} + Bunga
              Rp{{ toRupiah(state.dataPelunasan.sisabunga) }})
            </h4>
            <br />
            <v-btn large @click="handlePostPelunasan()" class="success"
              >REQUEST PELUNASAN</v-btn
            >
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Utils from "@/utils/util.js";
export default {
  name: "FormPelunasan",
  data() {
    return {
      e6: 1,
      currentStep: 0,
      step2: false,
      bukti: null,
      BuktiPelunasan: "",
    };
  },
  computed: {
    ...mapState("loan", {
      state: (state) => state,
    }),
    NoteReject() {
      const index = this.state.historyApproval.findIndex(
        (item) => item.ApprovalStatus === "RJT" && item.Notes !== null
      );
      if (index >= 0) {
        return this.state.historyApproval[index].Notes;
      }

      const index2 = this.state.historyApproval.findIndex(
        (item) => item.ApprovalStatus === "CNCL" && item.Notes !== null
      );
      if (index2 >= 0) {
        return this.state.historyApproval[index].Notes;
      }

      return "";
    },
  },
  methods: {
    ...mapActions("loan", [
      "modalStatusPengjuan",
      "postPelunasan",
      "kirimPelunasan",
    ]),
    handleKirimPelunasan() {
      const payload = { BuktiPelunasan: this.BuktiPelunasan };
      this.kirimPelunasan(payload).then(() => {
        this.$emit("updateStatusPelunasan");
      });
    },
    handlePostPelunasan() {
      this.postPelunasan().then(() => {
        this.$emit("updateStatusPelunasan");
      });
    },
    checkStep(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "RJT") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    getBase64() {
      let reader = new FileReader();
      const file = this.bukti;

      reader.onload = (e) => {
        this.BuktiPelunasan = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    checkNote(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "RJT") {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    checkComplete(JT) {
      const index = this.state.historyApproval.findIndex(
        (item) => item.Approvalby === JT
      );
      if (this.state.historyApproval.length > 0 && index >= 0) {
        if (this.state.historyApproval[index].ApprovalStatus === "APV") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    toRupiah(nominal) {
      return Utils.toRupiah(nominal);
    },
  },
};
</script>
<style>
.v-stepper__content.brd-green {
  color: aqua !important;
  border-color: aqua !important;
}
.v-file-input {
  max-width: 250px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 40px !important;
}
</style>
