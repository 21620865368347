import Api from "@/api";
import Host from "@/api/host";
import axios from "axios";
import authHeader from "../api/header";
import AuthService from "@/services/auth.service";

class PromotionService {
  getListAmortisasi(AgreementNo) {
    return Api.doGet(Host.LOAN_V1 + "/ListAmortisasi/" + AgreementNo);
  }
  getDetailPelunasan(AgreementNo) {
    return Api.doGet(Host.LOAN_V1 + "/DetailPelunasan/" + AgreementNo);
  }
  getHistoryAmortisasi(nik) {
    return Api.doGet(Host.LOAN_V1 + "/HistoryAmortisasi/" + nik);
  }
  getInquiryStatus(nik) {
    return Api.doGet(Host.LOAN_V1 + "/StatusPinjaman/" + nik);
  }

  getHistoryStatus(nik) {
    return Api.doGet(Host.LOAN_V1 + "/HistoryPinjaman/" + nik);
  }
  getHistoryAll(nik) {
    return Api.doGet(Host.LOAN_V1 + "/HistoryPinjamanAll/" + nik);
  }

  postPengajuanPinjaman(params) {
    return Api.doPost(Host.LOAN_V1 + "/PengajuanPinjaman", params);
  }

  postPengajuanPinjamanNST(params) {
    return Api.doPost(Host.LOAN_V1 + "/PengajuanPinjamanNST", params);
  }

  getStatusPelunasan(nik) {
    return Api.doGet(Host.LOAN_V1 + "/StatusPelunasan/" + nik);
  }

  getStatusPetermination(AgreementNo) {
    return Api.doGet(Host.LOAN_V1 + "/StatusPetermination/" + AgreementNo);
  }

  getHistoryPelunasan(nik) {
    return Api.doGet(Host.LOAN_V1 + "/HistoryPembayaran/" + nik);
  }

  postPelunasan(params) {
    return Api.doPost(Host.LOAN_V1 + "/Pelunasan", params);
  }
  getStepper(companyID) {
    return Api.doGet(Host.LOAN_V1 + "/StatusApproval/" + companyID);
  }
  getHistoryApproval(params) {
    return Api.doPost(Host.LOAN_V1 + "/HistoryApproval", params);
  }
  cancelPengajuan(params) {
    return Api.doPost(Host.LOAN_V1 + "/CancelPengajuan", params);
  }

  UploadBuktiPelunasan(id, params) {
    return axios({
      method: "POST",
      url: Host.LOAN_V1 + "/UploadBuktiPelunasan/" + id,
      headers: authHeader(),
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 403) {
          AuthService.refreshToken();
        }
        return error.response.data;
      });

    // return axios.post(`${process.env.VUE_APP_URL}api/v1/cms/loan/PreterminationApproval/${id}`, params,{
    //     headers: {
    //         'Authorization': "Bearer " + user.data.access_token,
    //         'X_USER_ID': user.data.id,
    //         'Content-Type': "application/json",
    //     }
    // }).then((response) => {
    //     return response.data;
    // })
    //     .catch((error) => {
    //         return error.response.data;
    //     });
  }
}

export default new PromotionService();
