<template>
    <div class="mt-4">
        <div class="container">
            <v-card-title class="hp-header">
                <div class="text-h5 font-weight-light">History Pinjaman</div>
                <v-btn color="warning" class="ml-20" @click="$router.go(-1)">Back</v-btn>
            </v-card-title>
            <v-card class="pa-2 ma-2">
                <v-data-table 
                    :headers="headers" 
                    :items="state.historyAll" 
                    loading-text="Loading... Please wait"
                    :loading="state.loading" 
                    class="elevation-1">
                    
                    <!-- Custom Templates -->
                    <template #[`item.index`]="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #[`item.ApprovalNo`]="{ item }">
                        {{ item.ApprovalNo }}
                    </template>
                    <template #[`item.SubmitDate`]="{ item }">
                        {{ item.SubmitDate ? toDate(item.SubmitDate) : "" }}
                    </template>
                    <template #[`item.LoanAmount`]="{ item }">
                        {{ item.LoanAmount > 0 ? toRupiah(item.LoanAmount) : "0" }}
                    </template>
                    <template #[`item.Status`]="{ item }">
                        <v-chip class="ma-2" small :color="getStatusColor(item.Status)" text-color="white">
                            {{ getStatusText(item.Status) }}
                        </v-chip>
                    </template>
                    <template #[`item.FinancePurpose`]="{ item }">
                        {{ item.FinancePurpose }}
                    </template>
                    <template slot="body.append">
                        <tr v-if="state.historyAll.length" class="black--text">
                            <th colspan="2">Total</th>
                            <th></th>
                            <th class="text-right">
                                {{ toRupiah(sumField("LoanAmount")) }}
                            </th>
                            <th></th>
                        </tr>
                    </template>
                    <template #[`item.action`]="{ item }">
                        <div class="action-pretermination">
                            <v-btn icon color="success" @click="openModal(item)">
                                <v-icon small>mdi-eye</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <!-- Modal -->
        <HistoryPinjamanModal 
        :isOpen="isDialogOpen" 
  :detail="selectedDetail"
  @update:isOpen="isDialogOpen = $event"
        />

        <Footer />
    </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Utils from "@/utils/util";
import Footer from "../../components/Footer.vue";
import HistoryPinjamanModal from "../../components/modal/HistoryPinjaman.vue";

export default {
    name: "HistoryPinjamanView",
    components: { Footer, HistoryPinjamanModal },
    data: () => ({
        headers: [
            { text: "No", value: "index", align: "center", sortable: false },
            { text: "Approval No", value: "ApprovalNo", sortable: false },
            { text: "Submit Date", value: "SubmitDate", sortable: false },
            { text: "Loan Amount", value: "LoanAmount", align: "right", sortable: false },
            { text: "Status", value: "Status", align: "center", sortable: false },
            { text: "Finance Purpose", value: "FinancePurpose", sortable: false },
            { text: "Action", sortable: false, value: "action", align: "center" },
        ],
        isDialogOpen: false, 
        selectedDetail: null, 
    }),
    computed: {
        ...mapState("loan", {
            state: (state) => state,
        }),
    },
    methods: {
        ...mapActions("loan", ["getHistoryAll"]),
        openModal(item) {
            this.selectedDetail = {
    ...item,
    ImagesPath: item.ImagesPath.replace(/\\\//g, "/"), 
  };
  this.isDialogOpen = true; 
        },
        toRupiah(nominal) {
            return Utils.toRupiah(nominal);
        },
        toDate(tanggal) {
            return Utils.toDateStringMount(tanggal);
        },
        sumField(key) {
            return this.state.historyAll.reduce((a, b) => a + (b[key] || 0), 0);
        },
        getStatusColor(status) {
            switch (status) {
                case "FUN": return "orange";
                case "RJT": return "red";
                case "APV": return "blue";
                case "FIN": return "yellow";
                case "CNCL": return "grey";
                default: return "grey";
            }
        },
        getStatusText(status) {
            switch (status) {
                case "FUN": return "FUNDING";
                case "RJT": return "REJECTED";
                case "APV": return "APPROVED";
                case "FIN": return "FINISHED";
                case "CNCL": return "CANCELLED";
                default: return "Unknown";
            }
        },
    },

    mounted() {
        const nik = this.$store.state.auth.user.user.nik || JSON.parse(localStorage.getItem('user')).NIK;
        if (nik) {
            this.getHistoryAll({ nik }); 
        }
    }
};
</script>
<style>
.hp-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>