import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index';
import StrukturOrganisasi from "../views/StrukturOrganisasi";
import Blog from "../views/Blog";
import Login from "../views/LoginV4.vue";
import Otp from "../views/Otp";
import ForgetPassword from "../views/ForgetPassword";
import ResetPassword from "../views/ResetPassword";
import Home from "../views/Home";
import ReportAmortisasi from './../views/ReportAmortisasi';
import Repayment from "../views/Repayment";
import SalaryReduction from "../views/SalaryReduction";
import SelfPayment from "../views/SelfPayment";
import Poins from "../views/Poins";
import LoanApplicationForm from "../views/new/LoanApplicationForm.vue";
import LoanApplicationFormProcess from "../views/LoanApplicationFormProcess";
import LoanApplicationFormApproved from "../views/LoanApplicationFormApproved.vue";
import LoanApplicationFormRejected from "../views/LoanApplicationFormRejected.vue";
import LoanApplicationFormCancel from "../views/LoanApplicationFormCancel.vue";
import LoanApplicationFormFunding from "../views/LoanApplicationFormFunding";
import Account from "../views/Account";
import Notification from "../views/Notification";
import EditAccount from "../components/account/EditAccount";
import Slug from "../components/blogs/Slug.vue";
import Faq from "../views/Faq"
import About from "../views/About";
import TermsAndConditions from "../views/TermsAndConditions";
import HistoryPoin from "../views/HistoryPoin";
import Store from "@/store"
import alerts from '../alerts/alert';
import HistoryPinjamanView from '../views/new/HistoryPinjaman.vue';
import Policy from '../views/new/Policy.vue';


Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/organisasi',
        name: 'StrukturOrganisasi',
        component: StrukturOrganisasi
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: Policy
    },
    {
        path: '/blog/slug',
        name: 'Slug',
        component: Slug
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/otp',
        name: 'OTP',
        component: Otp
    },
    {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: ForgetPassword
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/report-amortisasi/:AgreementNo',
        name: 'ReportAmortisasi',
        component: ReportAmortisasi
    },
    {
        path: '/repayment/{an}',
        name: 'Repayment',
        component: Repayment
    },
    {
        path: '/salary-reduction',
        name: 'SalaryReduction',
        component: SalaryReduction
    },
    {
        path: '/self-payment',
        name: 'SelfPayment',
        component: SelfPayment
    },
    {
        path: '/poins',
        name: 'Poins',
        component: Poins
    },
    {
        path: '/application-form',
        name: 'LoanApplicationForm',
        component: LoanApplicationForm
    },
    {
        path: '/application-form-process',
        name: 'LoanApplicationFormProcess',
        component: LoanApplicationFormProcess
    },
    {
        path: '/application-form-approved',
        name: 'LoanApplicationFormApproved',
        component: LoanApplicationFormApproved
    },
    {
        path: '/application-form-rejected',
        name: 'LoanApplicationFormRejected',
        component: LoanApplicationFormRejected
    },
    {
        path: '/application-form-cancel',
        name: 'LoanApplicationFormCancel',
        component: LoanApplicationFormCancel
    },
    {
        path: '/application-form-funding',
        name: 'LoanApplicationFormFunding',
        component: LoanApplicationFormFunding
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
    },
    {
        path: '/account/change',
        name: 'EditAccount',
        component: EditAccount
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/history-point',
        name: 'HistoryPoint',
        component: HistoryPoin
    },
    {
        path: '*',
        name: 'Index',
        component: Index
    },
    {
        path: '/history-pinjaman/{:nik}',
        name: 'HistoryPinjamanView',
        component: HistoryPinjamanView,

    }
]

const router = new VueRouter({
    hashbang: false,
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    root: '/',
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/terms-and-conditions', '/organisasi', '/about', '/login', '/blog', '/privacy-policy', '/forget-password', '/otp', '/reset-password', '/error', '/blog/slug'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('LOAN_SESSION');

    if (authRequired && !loggedIn) {
        next('/');
    } else {
        if (loggedIn) {
            const inquiry = Store.state.loan.inquiries;
            const user = Store.state.auth.user.user;
            if (to.name === 'LoanApplicationForm' && inquiry) {
                // alert(JSON.stringify(inquiry) + JSON.stringify(user))
                if (inquiry.Status === 'Sedang dalam approval' || user.email === null || user.mobilephoneno == null) {
                    alerts.alertDefalt('INFO', 'error','Anda belum bisa melakukan pengajuan Pinjaman')
                    next(from);
                }
            }
        }
        
        next();
    }
});

export default router;