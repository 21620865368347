<template>
    <v-dialog v-model="isOpen" persistent max-width="800px" transition="dialog-bottom-transition">
        <v-card class="elevation-10 rounded-lg">
            <v-card-title class="hp-modal-headline">
                <div class="modal-header-content">
                    <span class="modal-title">Detail History Pinjaman</span>
                    <img :src="detail.ImagesPath" alt="Loan Image" width="50px" class="modal-image" />
                </div>
                <v-btn icon @click="$emit('update:isOpen', false)" class="close-btn">
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="modal-content">
                <div v-if="detail">
                    <div class="hp-modal-rows-container">
                        <div class="hp-modal-first-row">
                            <!-- Informasi Dasar -->
                            <div class="section">
                                <h3 class="section-title">Informasi Dasar</h3>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-file-document</v-icon>
                                    <span><strong>Approval No:</strong> {{ detail.ApprovalNo }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-file-outline</v-icon>
                                    <span><strong>Agreement No:</strong> {{ detail.AgreementNo }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-calendar-clock</v-icon>
                                    <span><strong>Submit Date:</strong> {{ formatDate(detail.SubmitDate) }}</span>
                                </div>
                            </div>
    
                            <!-- Detail Pinjaman -->
                            <div class="section">
                                <h3 class="section-title">Detail Pinjaman</h3>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-currency-usd</v-icon>
                                    <span><strong>Loan Amount:</strong> {{ toRupiah(detail.LoanAmount) }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-briefcase</v-icon>
                                    <span><strong>Finance Purpose:</strong> {{ detail.FinancePurpose }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-percent</v-icon>
                                    <span><strong>Effective Rate:</strong> {{ detail.EffectiveRate }}%</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-file-account</v-icon>
                                    <span><strong>Jenis Pengajuan:</strong> {{ detail.JenisPengajuan }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="hp-modal-second-row">
                            <!-- Rekomendasi HC -->
                            <div class="section">
                                <h3 class="section-title">Rekomendasi HC</h3>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-checkbox-marked</v-icon>
                                    <span><strong>HC Checklist:</strong> {{ getYesNo(detail.HC_Checklist) }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                                    <span><strong>HC Recommendations:</strong> {{ getYesNo(detail.HC_Recommendations)
                                        }}</span>
                                </div>
                                <div class="detail-item">
                                    <v-icon color="primary">mdi-comment-text</v-icon>
                                    <span><strong>Note HC:</strong> {{ detail.Notes_HCRecommendation }}</span>
                                </div>
                            </div>
    
                            <!-- Status -->
                            <div class="section">
                                <h3 class="section-title">Status</h3>
                                <div class="status-item">
                                    <span :class="statusClass(detail.Status)">
                                        {{ getStatusText(detail.Status) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        getYesNo(value) {
            return value === 1 ? "YES" : "NO";
        },
        toRupiah(value) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
            }).format(value);
        },
        formatDate(date) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return new Date(date).toLocaleDateString("id-ID", options);
        },
        getStatusText(status) {
            switch (status) {
                case "FUN":
                    return "Funded";
                case "RJT":
                    return "Rejected";
                case "APV":
                    return "Approved";
                case "FIN":
                    return "Finished";
                case "CNCL":
                    return "Cancelled";
                default:
                    return "Unknown";
            }
        },
        statusClass(status) {
            switch (status) {
                case "FUN":
                    return "status-funded";
                case "RJT":
                    return "status-rejected";
                case "APV":
                    return "status-approved";
                case "FIN":
                    return "status-finished";
                case "CNCL":
                    return "status-cancelled";
                default:
                    return "status-unknown";
            }
        },
    },
};
</script>

<style scoped>
/* Global Layout */
.hp-modal-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-header-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-title {
    font-weight: bold;
    color: #333;
}

.modal-content {
    padding: 20px;
}

/* Section Styling */
.section {
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
}

/* Detail Items */
.detail-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.detail-item v-icon {
    font-size: 1.3rem;
}

/* Status Styling */
.status-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    font-weight: bold;
}

.status-funded {
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.status-rejected {
    background-color: #f44336;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.status-approved {
    background-color: #2196f3;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.status-finished {
    background-color: #ecec0d;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.status-cancelled {
    background-color: #9e9e9e;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.status-unknown {
    background-color: #000;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}
.hp-modal-first-row, .hp-modal-second-row{
    width: fit-content;
}
.hp-modal-rows-container{
    display: flex;
    gap: 100px;
    padding: 10px 0;
}
</style>
