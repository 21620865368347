<template>
  <div>
    <CheckLoan v-if="checkPendingLoan"/>
    <!-- <NavBar/> -->
    <div class="application-form" v-else>

      <div class="container pt-10">
        <Back/>
      </div>
      <div class="container">
        <div class="row text-center header">
          <div class="col-2 text-right d-none  d-sm-block">
            <img src="~@/assets/images/icons/formNew.png" width="100px" alt="">
          </div>
          <div class="col-10 text-left">
            <div class="mt-1">
              <h2 class="text-white">Silahkan isi form pengajuan pinjaman</h2>
              <h6 class="text-primary">
                Ikuti 4 langkah mudah untuk mengajukan pinjaman
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color:#E6F6FF !important">
        <div class="container text-center first mt-5">
          <h1 style="color: #0098EA !important;">1</h1>
          <h3>Tujuan Pinjaman</h3>
        </div>
        <div class="container loan-type">
          <b-icon icon="check" v-if="product.id" class="ml-2" font-scale="6" variant="success"></b-icon>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12" v-for="item in products" :key="item.ProductID">
              <div class="loan-type-content">
                <h6 class="mt-1">{{ item.FinancePurpos }}</h6>
                <h5 class="mt-2">{{ item.ProductName }}</h5>
                <img :src="item.ImagesPath" width="100px" alt="" class="mt-4">
                <div class="mt-4">
                  <button class="btn loan-btn-primary-outline w-50" @click="handleChooseProduct(item)">Ajukan</button>
                </div>
                <p class="mt-2">{{ item.ProductDescription }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center mt-4">
          <button class="btn">
            <b-icon icon="arrow-down-circle" style="width: 50px;height: 60px"></b-icon>
          </button>
        </div>
      </div>
      <div style="background-color:#ffffff !important">
        <div class="selfi container">
          <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="mt-5">
              <h1 style="color: #0098EA !important;">2</h1>
              <h2>Selfie dengan KTP/ID Card</h2>
              <ol>
                <li>Pastikan kartu identitas yang difoto adalah KTP/ID Card, bukan kartu identitas lainnya.</li>
                <!-- <li>Pastikan KTP/ID Card dalam bentuk format e-KTP dan masih aktif</li> -->
                <li>Pastikan NIK/ID Card, foto, dan data ID lainnya terbaca jelas (tidak blur atau gelap)</li>
                <li>KTP/ID Card adalah milik sendiri dan langsung diambil dari kamera HP sendiri.</li>
              </ol>
              <label class="btn loan-btn-primary m-4 w-50">
                <input type="file" id="file-input1"
                       @change="onFileChange1"
                       accept="image/*"/>
                Upload Foto
              </label>
              <b-icon icon="check" v-if="image1" class="ml-2" font-scale="6" variant="success"></b-icon><br>
                <span style="font-size:small;font-style:italic"><span style="color:red">*</span>File berupa (.jpg, .png)</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right mt-5">
            <img src="~@/assets/images/SelfieKTPNew.png" width="400px" alt="">
          </div>
        </div>
        </div>
        <div class="container text-center mt-5">
        <button class="btn">
          <b-icon icon="arrow-down-circle" style="width: 50px;height: 60px"></b-icon>
        </button>
      </div>
      </div>
      
      <div  style="background-color:#E6F6FF !important">
        <div class="selfi container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
              <img src="~@/assets/images/FotoPendukungNew.png" width="400px" alt="">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="mt-5">
                <h1 style="color: #0098EA !important;">3</h1>
                <h2>Foto Pendukung</h2>
                <h6>Lampirkan dokumen pendukung pinjaman (Mandatory) (contoh: Form Pengajuan yang sudah di lengkapi dengan tanda tangan pemohon, dan atasan pemohon, tagihan RS, tagihan SPP dan dokumen pendukung lainnya)</h6>
                
                <label class="btn loan-btn-primary m-2 w-50">
                  <input type="file" id="file-input2"
                         @change="onFileChange2"
                         accept="image/jpeg,image/gif,image/png,application/pdf"/>
                  Upload Form Pengajuan
                </label>
                <b-icon v-if="image2" icon="check" class="ml-2" font-scale="6" variant="success"></b-icon><br>
                <span style="font-size:small;font-style:italic"><span style="color:red">*</span>File berupa (.jpg / .png / .pdf)</span>
                <br>
                <label class="btn loan-btn-primary m-2 w-50">
                  <input type="file" id="file-input3"
                         @change="onFileChange3"
                         accept="image/jpeg,image/gif,image/png,application/pdf"/>
                  Upload Dokumen Pendukung
                </label>
                <b-icon v-if="image3" icon="check" class="ml-2" font-scale="6" variant="success"></b-icon><br>
                <span style="font-size:small;font-style:italic"><span style="color:red">*</span>File berupa (.pdf / .jpg / .png)</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center mt-5">
          <button class="btn">
            <b-icon icon="arrow-down-circle" style="width: 50px;height: 60px"></b-icon>
          </button>
        </div>
      </div>
      <div  style="background-color:#FFFFFF !important">
        <div class="selfi container">
          <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="mt-5">
              <h1>4</h1>
              <h2>Cicilan Pinjaman</h2>
              <ol>
                <li>Jumlah maksimal pinjaman Anda disesusaikan dengan saldo iuran pokok.</li>
                <li>Tenor Up to 23 Bulan</li>
                <!-- <li>Jangka waktu pelunasan terdapat 3 opsi, yakni selama 3 bulan, 6 bulan dan 12 bulan.</li> -->
                <li>Dengan melakukan peminjaman Anda setuju untuk dipotong gaji sejumlah pinjaman yang Anda
                  ajukan.
                </li>
              </ol>
              <button v-if="!isMobile()" class="btn loan-btn-primary m-4 w-50" @click="handleSubmit">Ajukan Pinjaman</button>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
            <div class="loan-amount">
              <div class="loan-value">
                <h6>Berapa jumlah yang ingin dipinjam?</h6>
                <div class="amount-slider">
                  <div class="slidecontainer">
                    <input type="range" min="0" :max="user.saldomaxpinjaman" step="500000" v-model="loanAmount"
                           @change="handleRate"
                           class="slider"
                           id="myRange">
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <h6>Rp 0 </h6>
                  </div>
                  <div class="col-6 text-right">
                    <h6>Rp {{ currency(user.saldomaxpinjaman) }} </h6>
                  </div>
                </div>
              </div>
              <h6 class="text-center mt-4 mb-4">Pinjaman Anda Rp {{ currency(loanAmount) }}</h6>
              <div class="loan-tenor">
                <h6>Jangka waktu pelunasan</h6>
                <select class="form-control form-control-lg" v-model="tenor" @change="handleCalculate">
                  <option v-show="tnr <= tenorMax && tnr >= 6" v-for="tnr, i in 36" :key="i" :value="tnr"> {{ tnr }} Bulan</option>
                  <!-- <option v-show="1 <=tenorMax" value="1">1 bulan</option>
                  <option v-show="3 <=tenorMax" value="3">3 bulan</option>
                  <option v-show="6 <=tenorMax" value="6">6 bulan</option>
                  <option v-show="9 <=tenorMax" value="9">9 bulan</option>
                  <option v-show="12 <=tenorMax" value="12">12 bulan</option>
                  <option v-show="13 <=tenorMax" value="13">13 bulan</option>
                  <option v-show="14 <=tenorMax" value="14">14 bulan</option>
                  <option v-show="16 <=tenorMax" value="16">16 bulan</option>
                  <option v-show="18 <=tenorMax" value="18">18 bulan</option>
                  <option v-show="20 <=tenorMax" value="20">20 bulan</option>
                  <option v-show="24 <=tenorMax" value="24">24 bulan</option>
                  <option v-show="25 <=tenorMax" value="25">25 bulan</option>
                  <option v-show="30 <=tenorMax" value="30">30 bulan</option>
                  <option v-show="32 <=tenorMax" value="32">32 bulan</option>
                  <option v-show="36 <=tenorMax" value="36">36 bulan</option> -->
                </select>
              </div>
              <div class="loan-amortisasi">
                <div class="container angsuran">
                  <h1>
                  <span>Rp. </span>{{ currency(installmentAmount.toFixed()) }}<span>/bulan</span></h1>
                  <hr>
                  <h6>Jumlah tenor sampai bulan ke-{{ tenor }}</h6>
                </div>
              </div>
              <div class="loan-faq">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="agreement">
                  <label class="form-check-label ml-2" for="flexCheckDefault">
                    Dengan ini saya setuju dengan syarat dan ketentuan dari pinjaman yang akan saya ajukan & akan
                    melunasinya
                  </label>
                </div>
              </div>
              <button v-if="isMobile()" class="btn loan-btn-primary m-4 w-50" @click="handleSubmit">Ajukan Pinjaman</button>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Back from '../../components/Back.vue';
import Footer from "@/components/Footer";
import Utils from '@/helper';
import CalculatorService from '@/services/calculator.service';
import LoanService from '@/services/loan.service';
import CheckLoan from '../../components/home/CheckLoan.vue';
import { mapActions } from 'vuex/dist/vuex.common.js';
// import router from "@/router";

export default {
  name: "LoanApplicationForm",
  components: {Footer, Back, CheckLoan},
  data() {
    return {
      image1: null,
      image2: null,
      image3: null,
      productId: '',
      loanAmount: 0,
      interest: 0,
      term_years: 0,
      terms: 0,
      agreement: false,
      installmentAmount: 0,
      tenor: 6,
      tenors: [],
      calculate: false,
      product: '',
      OSPrincipalAmount: 0,
      OSPInterestAmount: 0,
      tenorMax: 3,
      loanHistoryAll: []
    }
  },
  methods: {
    ...mapActions("loan", ["getHistoryAll"]),
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    alertPilih (message) {
      this.$swal.fire(
          'Opps...',
          message,
          'error'
      )
    },
    currency(nominal) {
      return Utils.currencyRp(nominal);
    },
    onFileChange1(e) {
      if (this.product === '') {
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage1(files[0]);
      this.$swal.fire(
          'Upload Foto',
          'Upload foto selfie dengan KTP',
          'success'
      )
    },
    createImage1(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image1 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange2(e) {
      if (this.product === '') {
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      if (this.image1 === '' || this.image1 === null) {
        this.alertPilih('Upload Selfie dengan KTP/ID Card terlebih dahulu')
        return false
      }
      let files = e.target.files || e.dataTransfer.files;
      if(files[0].size < 1000000)
      {
        if (!files.length) return;
        this.createImage2(files[0]);
        this.$swal.fire(
            'Upload Berhasil',
            'Upload Form Pengajuan',
            'success'
        )
      }else{
        this.$swal.fire(
              'Opps...',
              'File Form Pengajuan Terlalu Besar, Maks 1 Mb',
              'error'
          )
      }
    },
    createImage2(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image2 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange3(e) {
      if (this.product === '') {
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      if (this.image1 === '' || this.image1 === null) {
        this.alertPilih('Upload Selfie dengan KTP/ID Card terlebih dahulu')
        return false
      }
      let files = e.target.files || e.dataTransfer.files;
      console.log(files[0].size);
      if(files[0].size < 1000000)
      {
        if (!files.length) return;
            this.createImage3(files[0]);
            this.$swal.fire(
                'Upload Berhasil',
                'Upload Dokumen pendukung',
                'success'
            )
      }else{
        this.$swal.fire(
              'Opps...',
              'File Dokumen pendukung Terlalu Besar, Maks 1 Mb',
              'error'
          )
      }
    },
    createImage3(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image3 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleChooseProduct(product) {
      this.$swal.fire(
          'Product',
          'Anda memilih product ' + product.FinancePurpose,
          'success'
      )
      // this.interest = product.EffectiveRate;
      this.product = product;
    },
    handleRate() {
      if (this.product === '') {
        this.loanAmount = 0
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      if (this.image1 === '' || this.image1 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload Selfie dengan KTP/ID Card terlebih dahulu')
        return false
      }
      if (this.product.id) {
        let params = {
          ProductID: this.product.id,
          LoanAmount: this.loanAmount
        }
        let loading = this.$loading.show();
        CalculatorService.postChechRate(params).then(response => {
          if (response.code === 200) {
            this.tenorMax = parseInt(response.data.TenorMax);
            this.interest = response.data.EffectiveRate;
            if (parseInt(this.tenor) > parseInt(this.tenorMax)) {
              this.tenor = parseInt(this.tenorMax)
            }
            this.handleCalculate();
            loading.hide();
            
          } else {
            loading.hide();
            this.$swal.fire(
                'Opps...',
                'Minimal pinjaman Rp 500.000',
                'error'
            )
          }
        })
      } else {
        this.loanAmount = 0
        this.$swal.fire(
            'Alert!',
            'Pilih Tujuan Pinjaman terlebih dahulu',
            'warning'
        )
      }
    },
    handleCalculate() {
      if (this.product === '') {
        this.loanAmount = 0
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      if (this.image1 === '' || this.image1 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload Selfie dengan KTP/ID Card terlebih dahulu')
        return false
      }
      if (this.image2 === '' || this.image2 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload Form Pengajuan terlebih dahulu')
        return false
      }
      if (this.image3 === '' || this.image3 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload dokumen pendukung terlebih dahulu')
        return false
      }
      if (this.product.id) {
        let params = {
          id: this.product.id,
          loan_amount: this.loanAmount,
          interest: this.interest,
          term_years: parseInt(this.tenor) <= 6 ? 1 : 1,
          terms: parseInt(this.tenor)
        }
        let loading = this.$loading.show();
        CalculatorService.postCalculate(params).then(response => {
          if (response.code === 200) {
            console.log('Calculator' + JSON.stringify(response.data));
            this.installmentAmount = response.data.payment;
            this.OSPrincipalAmount = response.data.principal;
            this.OSPInterestAmount = response.data.interest;
            if (parseInt(this.tenor) > parseInt(this.tenorMax)) {
              this.tenor = parseInt(this.tenorMax)
            }
            loading.hide();
          } else {
            loading.hide();
            this.$swal.fire(
                'Error!',
                response.message,
                'error'
            )
          }
        })
      } else {
        this.$swal.fire(
            'Alert!',
            'Pilih Tujuan Pinjaman terlebih dahulu',
            'warning'
        )
      }
    },
    handleSubmit() {
      if (this.product === '') {
        this.loanAmount = 0
        this.alertPilih('Pilih Tujuan Pinjaman terlebih dahulu')
        return false
      }
      if (this.image1 === '' || this.image1 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload Selfie dengan KTP/ID Card terlebih dahulu')
        return false
      }
      if (this.image2 === '' || this.image2 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload Form Pengajuan terlebih dahulu')
        return false
      }
      if (this.image3 === '' || this.image3 === null) {
        this.loanAmount = 0
        this.alertPilih('Upload dokumen pendukung terlebih dahulu')
        return false
      }
      if (this.loanAmount === 0 || this.image3 === '0') {
        this.alertPilih('Jumlah pinjaman harus dipilih terlebih dahulu')
        return false
      }
      if (!this.agreement || this.agreement === false) {
        this.alertPilih('Klik setuju dengan syarat dan ketentuan terlebih dahulu')
        return false
      }
      if (this.image1 && this.product && this.agreement) {
        let params = {
          NIK: this.user.NIK,
          EmployeeName: this.user.name,
          ProductID: this.product.id,
          FinancePurpose: this.product.FinancePurpose,
          PhotoKTP: this.image1,
          PhotoPendukung: this.image2,
          DocPendukung: this.image3,
          loan_amount: this.loanAmount,
          interest: this.interest,
          term_years: this.tenor <= 12 ? 1 : 2,
          tenor: this.tenor,
          InstallmentAmount: this.installmentAmount,
          OSPrincipalAmount: this.OSPrincipalAmount,
          OSPInterestAmount: this.OSPInterestAmount,
          from_web:1
        }
        this.$store.dispatch('loading/setLoading', true);
        // let loading = this.$loading.show();
        LoanService.postPengajuanPinjaman(params).then(response => {
          // console.log(JSON.stringify(response.data));
          if (response.code === 200) {
            this.$store.dispatch('loading/setLoading', false);
            window.location = "/application-form-process";

            // router.push('/application-form-process')
            // loading.hide();
          } else {
            this.$store.dispatch('loading/setLoading', false);
            // loading.hide();
            this.$swal.fire(
                'Error!',
                response.message,
                'error'
            )
          }
        })
      } else {
        this.$swal.fire(
            'Alert!',
            'Silahkan lengkapi terlebih dahulu form pengajuan pinjaman',
            'warning'
        )
      }
    },
    getAll() {
      let loading = this.$loading.show();
      this.$store.dispatch('product/getAll').then(() => {
        loading.hide();
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    products() {
      return this.$store.state.product.data;
    },
    checkPendingLoan(){
    return (
        this.loanHistoryAll.filter((loan) => loan.status !== "FIN").length >= 2
      );
  }
  },
  mounted() {
    this.getAll();
      const nik = this.$store.state.auth.user.user.NIK || JSON.parse(localStorage.getItem('user')).NIK;
        if (nik) {
            this.getHistoryAll({ nik }); 
        }
  },
  

}
</script>

<style scoped>
/* .application-form {
  margin-top: 100px;
} */

.application-form .header {
  margin-top: 70px;
}

.application-form .header h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.application-form .header h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.application-form .first {
  margin-top: 120px;
  margin-bottom: 30px;
}

.application-form .first h1 {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  text-align: center;
  font-feature-settings: 'liga' off;
  color: #EBEBEB;
}

.application-form .first h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.4px;
  color: #263238;
}

.loan-type {
  margin-top: 50px;
}

.loan-type-content {
  text-align: center;
}

.loan-type-content img {
  border-radius: 360px;
  height: 100px;
  background-color: #2F3035;
}


.loan-type-content h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
}

.loan-type-content h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #1E0E62;
}

.loan-type-content p {
  padding: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #1E0E62;
}

.application-form input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* .application-form .selfi {
  margin-top: 50px;
} */

.application-form .selfi h1 {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  text-align: left;
  font-feature-settings: 'liga' off;
  color: #EBEBEB;
}

.application-form .selfi h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #263238;
}

.application-form .selfi h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #767676;
}

.application-form .selfi ol li {
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #767676;
}

.loan-amount {
  padding: 30px;
  top: 0px;
  background: #F6F7FB;
  border-radius: 10px;
}

.loan-amount .loan-value {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #D4DFEF;
  box-sizing: border-box;
  border-radius: 10px;
}

.loan-amount .loan-value h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  align-items: flex-end;
  color: #8D97B5;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #1E0E62;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FFEB3B;
  cursor: pointer;
}

.loan-amount .loan-tenor {
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #D4DFEF;
  box-sizing: border-box;
  border-radius: 10px;
}

.loan-amount .loan-amortisasi {
  padding: 60px;
  text-align: center;
  margin-top: 40px;
  background: #FFEB3B;
  border-radius: 10px;
}

.loan-amount .loan-amortisasi .container span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 41px;
  text-align: center;
  color: #1E0E62;
}

.loan-amount .loan-amortisasi .container h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 28px;
  text-align: right;
  color: #1E0E62;
}

.loan-amount .loan-amortisasi .container h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #1E0E62;
}

.loan-amount .loan-faq {
  margin-top: 20px;
  padding: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #8D97B5;
}

.loan-amount .loan-faq input[type=checkbox] {
  transform: scale(2);
  margin-top: 10px;
}
</style>