<template>
  <v-app>
    <v-app-bar
      app
      color="#0055a3"
      elevate-on-scroll
      dark
      :class="is_mobile() ? '':'desktop'"
    >
      <div class="d-flex align-center">
          <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          @click="getIndex()"
          src="~@/assets/images/bfi.png"
          transition="scale-transition"
          width="40"
          style="cursor: pointer;"
        />
        <h4 class="mt-2 pointer" style="cursor: pointer;" @click="getIndex()">Bakti Fajar Idaman</h4>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isLogin">
        <router-link v-for="mn in auths" :key="mn.title" class="menu hidden-sm-and-down" :to="mn.url" link>{{ mn.title }}</router-link>
        <!-- <router-link class="menu hidden-sm-and-down" to="/application-form" link>Pinjaman</router-link>
        <router-link class="menu hidden-sm-and-down" to="/poins" link>Poin</router-link>
        <router-link class="menu hidden-sm-and-down" to="/blog" link>Blog</router-link>
        <router-link class="menu hidden-sm-and-down" to="/faq" link>FAQ</router-link> -->
        <router-link to="/notification" class="btn">
          <!-- <v-badge :content="0"> -->
            <v-icon>mdi-bell-outline</v-icon>
          <!-- </v-badge> -->
          <!-- <v-icon>mdi-bell-outline</v-icon> -->
        </router-link>
        <router-link to="/account" class="btn menu hidden-sm-and-down">
          <v-icon>mdi-account</v-icon>
        </router-link>
      </div>
      <div v-else>
        <router-link v-for="mn in items" :key="mn.title" class="menu hidden-sm-and-down" :to="mn.url" link>{{ mn.title }}</router-link>
        <!-- <router-link class="menu hidden-sm-and-down" to="/about" link>Tentang Kami</router-link>
        <router-link class="menu hidden-sm-and-down" to="/organisasi" link>Struktur Organisasi</router-link>
        <router-link class="menu hidden-sm-and-down" to="/blog" link>Blog</router-link> -->
      </div>

      <v-btn
        v-if="!isLogin"
        to="/login"
        class="white hidden-sm-and-down"
        rounded
      >
        <span class="mr-2 text-black">MASUK</span>
        <!-- <v-icon>mdi-open-in-new</v-icon> -->
      </v-btn>
      <v-btn
        class="hidden-lg-and-up"
        icon
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        color="#04559F"
        dark
      >
        <v-list-item class="pt-2">
          <v-list-item-avatar @click="getIndex()">
            <v-img src="~@/assets/images/bfi.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content @click="getIndex()">
            <v-list-item-title>Bakti Fajar Idaman</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="isLogin" class="text-right">
          <v-btn icon small class="mr-2 text-right"><v-icon>mdi-logout</v-icon></v-btn>
          <div class="justify-center text-center" justify="center">
            <v-avatar @click="getProfile()"><img :src="user.image"/></v-avatar> <br>
            <span class="text-white">{{ user.name }}</span>
          </div>
          <v-divider></v-divider>
        </div>
        
        <v-list v-if="!isLogin" dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  <v-btn x-small @click="getLogin()" class="warning">Login</v-btn>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else dense>
          <v-list-item
            v-for="item in auths"
            :key="item.title"
            link
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    <v-main>
      <router-view/>
      <loadingVue/>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import loadingVue from './Loading/loading.vue';
import router from './router';

export default {
  name: 'App',
  components: {
    loadingVue
  },
  data: () => ({
    drawer: null,
    items: [
      { title: 'Tentang Kami', url: '/about', icon: 'mdi-view-dashboard' },
      { title: 'Struktur Organisasi', url: '/organisasi', icon: 'mdi-account-group' },
      { title: 'Blog', url: '/blog', icon: 'mdi-earth' },
      { title: 'Privacy Policy', url: '/privacy-policy', icon: 'mdi-earth' },
      // { title: 'Login', url: '/login', icon: '' },
    ],
    auths: [
      { title: 'Home', url: '/home', icon: 'mdi-home' },
      { title: 'Pinjaman', url: '/application-form', icon: 'mdi-cash' },
      { title: 'Poin', url: '/poins', icon: 'mdi-crystal-ball' },
      { title: 'Blog', url: '/blog', icon: 'mdi-earth' },
      { title: 'FAQ', url: '/faq', icon: 'mdi-frequently-asked-questions' },
    ],
  }),
  created() {
    if (this.isLogin) {
      this.$store.dispatch('loan/getInquiries',this.user.NIK)
      this.$store.dispatch('loan/getStatusPelunasan',this.user.NIK)
    }
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user.user,
      isLogin: (state) => state.status.loggedIn,
    }),
    ...mapState('loan', {
      inquiry: (state) => state.inquiries
    })
  },
  methods: {
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 760px)')
      return !!isMobile.matches
    },
    getIndex () {
      if (this.isLogin) {
          router.push('/home')
      } else {
        router.push('/')
      }
    },
    getProfile(){
      router.push('/account')
    },
    getLogin(){
      router.push('/login')
    }
  }
};
</script>
<style>
.desktop{
  padding-left: 5vmax;
  padding-right: 5vmax;
}
.menu{
  margin-right: 2vmax;
  color: #0098EA !important;
}
.menu.active{
  margin-right: 2vmax;
  color: #FFFFFF !important;
}
</style>